import styled from 'styled-components'

export const Container = styled.div`
    width: 90%;
    /* max-height: 100px; */
    background-color: #fff8;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    flex: 0 0 auto; 
    overflow-y: hidden;

    @media only screen and (max-width: 480px) {
       flex-direction: column;
    }
`
export const ImageWrapper = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 10px;
    overflow: hidden;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media only screen and (max-width: 480px) {
        width: 100%;
        height: auto;   
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`
export const TextWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 10px;
    font-size: 0.8em;
    
    & p {
        margin: 0;
        text-align: start;
    }
`
export const DateText = styled.span`
    font-weight: 700;
`