import React from "react"
import * as Api from "../../Service/modules/news"
import Item from "./Item"
import { Container, Scrollable, Title } from "./styles.styled"
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import './custom-carousel/style.css'



function News(props: any) {

    const { style } = props

    const [posts, setPosts] = React.useState<Array<any>>([])

    React.useEffect(() => {
        const loadPosts = async () => {

            const p: Array<any> = await Api.newsList()

            setPosts(p)
        }

        loadPosts()
    }, [])

    if (!posts.length) return null

    return <Container style={style}>
        <Title >مستجدات ملف السيارات</Title>

        <div dir="ltr">
            <Carousel draggable={false} plugins={['arrows']}>
                {posts.map((post: any) => <Item key={post.id} post={post} />)}
            </Carousel>
        </div>
    </Container>
}

export default News