import styled from 'styled-components'

export const Container = styled.div`
`
export const Scrollable = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 10px;
`
export const Title = styled.h3`
    text-align: start;
    margin-bottom: 0;
    font-size: 1em;
`