const ROOT_URL = 'https://content.tonobil.online/wp-json/wp/v2'
const CATEGORY_NEWS_ID = 2

export const newsList = async () => {
    
    // _embed is not optimal when only thumbnail is needed
    // Check SOW: https://wordpress.stackexchange.com/a/249769/227258
    const url = `${ROOT_URL}/posts?categories=${CATEGORY_NEWS_ID}&_embed`
    
    return await fetch(url).then(res => res.json())
}