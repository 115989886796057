import React from "react";
import {
  Container, Input, ResultBox, CalculatorContainer,
  Title, Footer, Amount, Currency, InputContainer, InputCurrency, Info, Row
} from "./styles.styled";
import * as Calculator from "../Utils/Calculator";
import Lottie from 'react-lottie';
import animationData from '../assets/animations/car.json'
import News from "../Components/News";



function Home() {

  const [priceEuro, setPriceEuro] = React.useState()
  const [finalPrice, setFinalPrice] = React.useState({ price: 0, letters: '' })
  const refInput = React.useRef()
  const [officialRate, setOfficialRate] = React.useState()
  const [blackRate, setBlackRate] = React.useState()

  React.useEffect(() => { focusInput() }, [])

  React.useEffect(() => {

    const getChangeRates = async () => {

      const { official, parallel } = await Calculator.getRates()

      setOfficialRate(official)

      setBlackRate(parallel)
    }

    getChangeRates()
  }, [])

  const focusInput = () => {
    refInput?.current?.focus()
  }

  const onTextCahged = async (value) => {

    setPriceEuro(value)

    const { price, letters } = await Calculator.calculate(value ? value : 0)

    setFinalPrice({ price, letters })
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
  };

  return (
    <Container>

      <div>
        <Lottie
          options={defaultOptions}
          height={100} />

        <Title style={{ marginTop: -30 }}>Tonobil</Title>
      </div>

      <p>حساب سعر استيراد السيارات الأقل من ثلاث سنوات</p>

      <CalculatorContainer>

        <ResultBox>
          <Row>
            <Amount dir="ltr">{finalPrice.price}</Amount>
            <Currency>دج</Currency>
          </Row>
          <Info style={{ textAlign: 'center', margin: 0 }}>{finalPrice.letters}</Info>
        </ResultBox>

        <InputContainer>

          <Input
            ref={refInput}
            inputMode="numeric"
            type="number"
            placeholder="الثمن باليورو"
            value={priceEuro}
            onChange={(e) => onTextCahged(e.target.value)}
          />

          <InputCurrency>€</InputCurrency>
        </InputContainer>

        <Info>ثمن الشراء بدون الضريبة على القيمة المضافة</Info>

        <News style={{ marginTop: 50 }} />

      </CalculatorContainer>



      <Footer>
        <b>الضرائب و أسعار التحويل المستعملة</b>

        <ul>
          <li>القيمة المضافة: <b>{Calculator.FEES_TVA * 100}%</b></li>
          <li>الجمارك: <b>{Calculator.FEES_CUSTOMS * 100}%</b></li>
          <li>الضمان: <b>{Calculator.FEES_TCS * 100}%</b></li>
          <li>الصرف الرسمي: <b>{officialRate}دج</b></li>
          <li>الصرف الموازي: <b>{blackRate}دج</b></li>
        </ul>
      </Footer>
    </Container>
  );
}

export default Home;
