import Home from "./Home";


function App() {
  return (
    <div className="App" dir="rtl">
      <Home />
    </div>
  );
}

export default App;
