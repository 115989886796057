import React from "react"
import { Container, DateText, ImageWrapper, TextWrapper } from "./styles.styled"
import moment from 'moment';
import 'moment/locale/ar-dz'


function Item(props: any) {

    const { style, post } = props

    const image = React.useMemo(() => {

        const medias: Array<any> = post._embedded['wp:featuredmedia']

        const { location } = window

        const { protocol, hostname, port } = location

        const root = protocol + '//' + hostname + (port ? `:${port}` : "")

        if (!medias?.length) return `${root}/404.png`

        const firstMedia = medias[0]

        const mediumImage = firstMedia?.media_details?.sizes?.medium

        const image = mediumImage ?? firstMedia?.media_details?.sizes?.full
        
        return image?.source_url

    }, [post])

    return <Container style={style} dir="rtl">
        <ImageWrapper>
            <img alt="article" src={image} width={80} height={80} />
        </ImageWrapper>

        <TextWrapper>
            <DateText>{moment(post.date).format('D MMM yyyy')}</DateText>
            <p dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
        </TextWrapper>
    </Container>
}


export default Item