import styled from "styled-components"

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    height: 100vh;
    max-width: 600px;
    margin: 0 auto;
`
export const Info = styled.small`
    display: inline-block;
    width: 100%;
    margin-top: 6px;
    text-align: start;
    font-size: 0.7em;
    font-family: Main;
`
export const Title = styled.h1`
    align-self: center;
    font-family: LuckiestGuy;
`
export const CalculatorContainer = styled.div`
    flex: 1;
`
export const ResultBox = styled.div`
    display:flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    min-height: 99px;
    padding: 10px 20px;
    box-sizing: border-box;
    
    font-family: NotoNastaliq;
    word-wrap: break-word;
    
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: solid #ddd 1px;
    border-bottom: none;
`
export const Amount = styled.span`
    font-size: 3em;
    padding-bottom: 0.75em;
    line-height: 0.75em;
`
export const Currency = styled.span`
`
export const InputContainer = styled.div`
    display: flex;
    overflow: hidden;
    background-color: #FFF;
    align-items: center;
    box-sizing: border-box;
    padding: 4px 10px;
    font-family: inherit;
    
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: solid #ddd 1px;
    border-top: none;
`
export const Input = styled.input`
    height: 40px;
    width: 100%;
    border: none;
    border-radius: 0;
    font-family: inherit;
    font-size:1em;
    padding-top: 8px;
    box-sizing: border-box;

    &:focus {
        outline: none;
        /* box-shadow: 0px 0px 2px gray; */
    }
`
export const InputCurrency = styled.span`
    color: gray;
    padding-top: 4px;
`
export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.75em;
    & ul {
        display: flex;
        flex-wrap: wrap;
        gap: 0 4px;
        margin-top: 5px;
        justify-content: space-around;
    }
`