
export const formatPrice = (price: string): string => {

    let result = []

    const len = price.length

    for (let i = len - 1; i >= 0; i--) {

        const reverseIndex = (len - i - 1) % len

        if (reverseIndex % 3 === 0) result.unshift(' ')

        result.unshift(price[i])
    }

    return result.join('').trim()
}

// 
export const priceToLettersInCents = (price: number): string => {

    const ONE_BILLION = 10000000
    const ONE_MILLION = 10000

    // Get the number of billions
    const billions = Math.floor(price / ONE_BILLION)

    const millions = Math.floor((price - billions * ONE_BILLION) / ONE_MILLION)

    const billionLetters = _billionLetters(billions)

    const millionLetters = _millionLetters(millions)

    const parts = [billionLetters, millionLetters].filter(p => !!p)

    const priceInLetters = parts.join(' و ')

    const unit = ' سنيتم'

    return `${priceInLetters} ${unit}`
}

const _billionLetters = (billions: number): string => {

    if (billions === 0) return ''

    let letter = ''

    if (billions >= 11) letter = 'مليار'

    else if (billions >= 2) letter = 'ملاير'

    else letter = 'مليار'

    return `${billions} ${letter}`
}

const _millionLetters = (millions: number): string => {

    if (millions === 0) return ''

    let letter = ''

    if (millions >= 11) letter = 'مليون'

    else if (millions >= 2) letter = 'ملاين'

    else letter = 'مليون'

    return `${millions} ${letter}`
}